import React from "react";
import Layout from "../pages";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import NoPage from "../pages/NotFound";
import Products from "../pages/products";
import Urinals from "../pages/products/Urinals";
import HandDryers from "../pages/products/HandDryers";
import MBActiveTrap from "../pages/products/MBActiveTrap";
import BiologicalCleaner from "../pages/products/BiologicalCleaner";
import FXMWashbasinModular from "../pages/products/FXMWashbasinModular";
import PartitionWalls from "../pages/products/PartitionWalls";
import InWallMountingFrame from "../pages/products/InWallMountingFrame";
import UrimatConnect from "../pages/products/UrimatConnect";
import Disinfection from "../pages/products/Disinfection";
import Services from "../pages/services";
import VideoInstructions from "../pages/services/VideoInstructions";
import NewsUpdates from "../pages/services/NewsUpdates";
import Downloads from "../pages/services/Downloads";
import Savings from "../pages/services/Savings";
import FAQ from "../pages/services/FAQ";
import AboutUs from "../pages/about-us";
import GuidingPrinciples from "../pages/about-us/GuidingPrinciples";
import References from "../pages/about-us/References";
import Contact from "../pages/contact/Contact";
import Overview from "../pages/services/Overview";
import AboutUsOverview from "../pages/about-us/Overview";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/products" element={<Products />}>
          <Route index element={<Urinals />} />
          <Route path="urinals" element={<Urinals />} />
          <Route path="hand-dryers" element={<HandDryers />} />
          <Route path="mb-activetrap" element={<MBActiveTrap />} />
          <Route path="biological-cleaner" element={<BiologicalCleaner />} />
          <Route
            path="fx-m-washbasin-modular"
            element={<FXMWashbasinModular />}
          />
          <Route path="partition-walls" element={<PartitionWalls />} />
          <Route
            path="in-wall-mounting-frame"
            element={<InWallMountingFrame />}
          />
          <Route path="urimat-connect" element={<UrimatConnect />} />
          <Route path="disinfection" element={<Disinfection />} />
        </Route>
        <Route path="/services" element={<Services />}>
          <Route index element={<Overview />} />
          <Route path="instruction-videos" element={<VideoInstructions />} />
          <Route path="news-updates" element={<NewsUpdates />} />
          <Route path="downloads" element={<Downloads />} />
          <Route path="savings-1" element={<Savings />} />
          <Route path="faq" element={<FAQ />} />
        </Route>
        <Route path="/about-us" element={<AboutUs />}>
          <Route index element={<AboutUsOverview />} />
          <Route path="guiding-principles" element={<GuidingPrinciples />} />
          <Route path="references" element={<References />} />
        </Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
