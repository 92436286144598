import React, { useState } from "react";
import { ItemCard5 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";
export default function PartitionWalls(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div className="w-full min-h-[65%] bg-gray-200 gap-8 overflow-y-auto flex items-center">
        <div
          className="w-1/2 h-full bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("https://www.urimat.com/fileadmin/produkte/trennwaende/header-trennwaende.png")`,
          }}
        />
        <div className="w-1/3 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            Partition wall and design element all in one
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Dry your hands in 9-11 seconds. Quiet, fast and environmentally
            friendly.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
        </div>
      </div>
      <div className="w-[60%] flex flex-col items-center justify-center top-12 gap-16 my-16">
        <div className="flex gap-8 w-full">
          <div className="text-base text-txtTT w-1/2">
            Privacy screens/walls are being increasingly used in sanitary
            facilities. In addition to hygiene, privacy is also very important
            here. The decorative surfaces of our partition walls are widely
            resistant to all standard household solvents and chemicals. The
            material has therefore been used for many years in applications
            where cleanliness and hygiene are essential. They don’t need any
            maintenance, just cleaning. These durable partition walls have a
            smooth surface, good hygiene standards and are extremely robust.
            URIMAT partition walls made from Trespa® and glass are particularly
            easy to clean and also offer lots of design options in your toilet
            facility.
          </div>
          <div className="bg-white shadow-card relative w-1/2 rounded-tl-3xl rounded-br-3xl p-6 pb-20 flex flex-col items-center">
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
        </div>
        <div className="flex row-span-3 gap-8">
          <ItemCard5
            key="URIMAT eco"
            header1="URIMAT eco"
            description1="Hightech-plastic waterless urinal"
            imageUrl1="https://www.urimat.com/fileadmin/produkte/trennwaende/urimat-trennwand-glas.png"
            header2="Information"
            description2={
              <ul className="flex flex-col gap-3 list-disc list-inside">
                <li>Item no.: 35.202</li>
                <li>Glass thickness: 8 mm</li>
                <li>
                  Properties of glass: Toughened safety glass / Nano-repellent
                </li>
                <li>Bracket finish: Polished Chrome</li>
                <li>Packaging weight: 9 kg</li>
              </ul>
            }
            imageUrl2="https://www.urimat.com/fileadmin/user_upload/35.200-a001-00_urimat_esg-wall_partition.png"
            url="/products/urinals"
            title="Further Information"
          />
          <ItemCard5
            key="URIMAT ecoplus"
            header1="URIMAT ecoplus"
            description1="Hightech-plastic waterless urinal with advertising display"
            imageUrl1="https://www.urimat.com/fileadmin/produkte/trennwaende/urimat-trennwand-glas.png"
            header2="Information"
            description2={
              <ul className="flex flex-col gap-3 list-disc list-inside">
                <li>Item no.: 35.202</li>
                <li>Glass thickness: 8 mm</li>
                <li>
                  Properties of glass: Toughened safety glass / Nano-repellent
                </li>
                <li>Bracket finish: Polished Chrome</li>
                <li>Packaging weight: 9 kg</li>
              </ul>
            }
            imageUrl2="https://www.urimat.com/fileadmin/user_upload/35.200-a001-00_urimat_esg-wall_partition.png"
            url="/products/urinals"
            title="Further Information"
          />
          <ItemCard5
            key="URIMAT ecovideo"
            header1="URIMAT ecovideo"
            description1="Hightech-plastic waterless urinal with LCD video display"
            imageUrl1="https://www.urimat.com/fileadmin/produkte/trennwaende/urimat-trennwand-glas.png"
            header2="Information"
            description2={
              <ul className="flex flex-col gap-3 list-disc list-inside">
                <li>Item no.: 35.202</li>
                <li>Glass thickness: 8 mm</li>
                <li>
                  Properties of glass: Toughened safety glass / Nano-repellent
                </li>
                <li>Bracket finish: Polished Chrome</li>
                <li>Packaging weight: 9 kg</li>
              </ul>
            }
            imageUrl2="https://www.urimat.com/fileadmin/user_upload/35.200-a001-00_urimat_esg-wall_partition.png"
            url="/products/urinals"
            title="Further Information"
          />
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
