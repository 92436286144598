import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

const ItemCard = (props) => {
  return (
    <div className="bg-white shadow-card relative w-full aspect-[3/5] rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center">
      <div>
        {props.header === "" ? (
          ""
        ) : (
          <h2 className="text-primary font-bold font-sans text-2xl card-title">
            {props.header}
          </h2>
        )}
        <p className=" mt-2">{props.description}</p>
      </div>
      <div
        className="cardsub bg-no-repeat bg-contain bg-bottom relative w-full aspect-[1/1]"
        style={{
          bottom:"0",
          position:"absolute",
          backgroundImage: `url(${props.imageUrl})`,
        }}
      />
      <Link
        to={props.url}
        className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-xl absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
      >
        {props.title}
      </Link>
    </div>
  );
};

const ItemCard2 = (props) => {
  return (
    <div className="p-6  bg-white shadow-card bg-no-repeat bg-contain bg-bottom w-72 relative rounded-tl-3xl rounded-br-3xl flex flex-col items-center">
      <div className="w-full h-full pb-8">
        <div
          className="bg-white bg-no-repeat bg-contain bg-bottom w-full aspect-[1/1] p-6 flex flex-col items-center"
          style={{
            backgroundImage: `url(${props.imageUrl})`,
          }}
        />
        <div>
          {props.header === "" ? (
            ""
          ) : (
            <h2 className="text-primary font-bold font-sans text-2xl card-title">
              {props.header}
            </h2>
          )}
          <p className=" mt-2">{props.description}</p>
        </div>
      </div>
      {/* <Link
        to={props.url}
        className="rounded-full hover:cursor-pointer hover:scale-105 text-white font-thin absolute -bottom-7 text-xl flex justify-center items-center aspect-[1/1] h-14 bg-primary uppercase"
      >
        <ArrowRightOutlined />
      </Link> */}
    </div>
  );
};

const ItemCard3 = (props) => {
  return (
    <div className="bg-white shadow-card relative w-full aspect-[4/6] rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-between pb-20 items-center">
      <div className="gap-2 flex flex-col">
        {props.header === "" ? (
          ""
        ) : (
          <h2 className="text-primary font-normal font-sans text-2xl card-title">
            {props.header}
          </h2>
        )}
        <p className="font-light">{props.description}</p>
      </div>
      <div
        className=" bg-no-repeat bg-contain bg-bottom w-full aspect-[1/1]"
        style={{
          backgroundImage: `url(${props.imageUrl})`,
        }}
      />
      {/* <Link
        to={props.url}
        className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
      >
        <ArrowRightOutlined />
        {props.title}
      </Link> */}
    </div>
  );
};

const ItemCard4 = (props) => {
  return (
    <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-betweenitems-center">
      <div
        className={`bg-no-repeat bg-contain bg-bottom w-full aspect-[${props.imageAspect}]`}
        style={{
          backgroundImage: `url(${props.imageUrl})`,
        }}
      />
      <div className="flex flex-col text-txtTT gap-4 justify-between ">
        <h2 className="font-normal font-sans text-2xl card-title">
          {props.title}
        </h2>
        <div className="font-light h-2/3">{props.description}</div>
      </div>
    </div>
  );
};

const ItemCard5 = (props) => {
  return (
    <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 flex flex-col gap-6 text-txtTT">
      <div
        className=" bg-no-repeat bg-contain bg-center w-full aspect-[1/1]"
        style={{
          backgroundImage: `url(${props.imageUrl1})`,
        }}
      />
      <div className="gap-2 flex flex-col">
        {props.header1 === "" ? (
          ""
        ) : (
          <h2 className="font-normal font-sans text-2xl card-title">
            {props.header1}
          </h2>
        )}
        <p className="font-light">{props.description1}</p>
      </div>
      <div className="text-2xl">Dimensions (in mm)</div>
      <div
        className=" bg-no-repeat bg-contain bg-bottom w-full aspect-[1/2]"
        style={{
          backgroundImage: `url(${props.imageUrl2})`,
        }}
      />
      <div className="gap-2 flex flex-col">
        {props.header2 === "" ? (
          ""
        ) : (
          <h2 className="font-normal font-sans text-2xl card-title">
            {props.header2}
          </h2>
        )}
        <p className="font-light">{props.description2}</p>
      </div>
    </div>
  );
};

export { ItemCard, ItemCard2, ItemCard3, ItemCard4, ItemCard5 };
