import React, { useState } from "react";
import { ItemCard3 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";

export default function FXMWashbasinModular(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div className="w-full min-h-[65%] bg-gray-200 gap-8 overflow-y-auto flex items-center">
        <div
          className="w-1/2 min-h-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("https://www.urimat.com/fileadmin/produkte/fx-m/urimat_header_fx_m_wash_station_white-5.png")`,
          }}
        />
        <div className="w-1/3 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            FX-M Washbasin Modular
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Dry your hands in 9-11 seconds. Quiet, fast and environmentally
            friendly.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
        </div>
      </div>
      <div className="w-[60%] flex flex-col justify-center top-12 gap-24 my-16">
        <div className="flex justify-center gap-6">
          <div className="text-txtTT font-light w-1/2 flex flex-col gap-4">
            <div className="text-primary text-3xl font-light">New design</div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
          </div>
          <div className="text-txtTT font-light w-1/2 bg-white shadow-card rounded-tl-3xl rounded-br-3xl flex flex-col justify-between">
            <div className="p-6 flex flex-col gap-3">
              <div className=" text-primary text-3xl font-light">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div
              className="w-full h-[65%] bg-cover bg-center bg-no-repeat rounded-br-3xl"
              style={{
                backgroundImage: `url("https://www.urimat.com/fileadmin/produkte/fx-m/urimat_header_fx_m_wash_station_white-5.png")`,
              }}
            />
          </div>
        </div>
        <div className="flex gap-8 w-full">
          <div className="bg-white shadow-card w-2/3 rounded-tl-3xl rounded-br-3xl flex flex-col justify-between">
            <div className="flex flex-col gap-3 p-6">
              <div className="text-primary text-3xl font-light ">
                The technology of waterless urinals
              </div>
              <div className="text-base text-txtTT flex flex-col gap-4">
                <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
                  <li>Environmentally friendly</li>
                  <li>Cost-effective</li>
                  <li>Quick and efficient</li>
                  <li>Simple installation</li>
                  <li>Robust & durable</li>
                </ul>
              </div>
            </div>
            <video controls className="w-full aspect-[16/9] rounded-br-3xl">
              <source
                src="https://www.urimat.com/fileadmin/user_upload/installation_of_fx-m_modular_washbasin_unit_urimat.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="bg-white shadow-card relative w-1/3 rounded-tl-3xl rounded-br-3xl p-6 pb-20 flex flex-col items-center">
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-primary text-3xl font-light ">
            The technology of waterless urinals
          </div>
          <div className="flex w-full gap-6">
            <div className="w-2/3 flex flex-col gap-8">
              <div className="text-base text-txtTT flex flex-col gap-4">
                <div className="w-full list-decimal font-extralight">
                  A hand dryer is an electrical device for drying hands in the
                  sanitary room by means of a generated cold or warm air flow.
                  The integrated motor draws in the ambient air via a blower and
                  then blows the heated air back via a germ filter (HEPA filter)
                  into the air duct for drying hands. The function is triggered
                  by a sensor to which you hold your hands. The warm air that
                  flows out of the hand dryer ensures dry hands within a few
                  seconds by simply evaporating the moisture - without the use
                  of paper or cloth towels.
                </div>
              </div>
            </div>
            <div
              className="w-1/3 aspect-[8/9] shadow-card bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage:
                  "url(https://www.urimat.com/fileadmin/produkte/fx-m/fx_m_touchless_wash_station_inside_urimat_.png)",
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-primary text-3xl font-light ">
            The technology of waterless urinals
          </div>
          <div className="flex w-full gap-6">
            <div className="w-2/3 flex flex-col gap-8">
              <div className="text-base text-txtTT flex flex-col gap-4">
                <div className="w-full list-decimal font-extralight">
                  A hand dryer is an electrical device for drying hands in the
                  sanitary room by means of a generated cold or warm air flow.
                  The integrated motor draws in the ambient air via a blower and
                  then blows the heated air back via a germ filter (HEPA filter)
                  into the air duct for drying hands. The function is triggered
                  by a sensor to which you hold your hands. The warm air that
                  flows out of the hand dryer ensures dry hands within a few
                  seconds by simply evaporating the moisture - without the use
                  of paper or cloth towels.
                </div>
              </div>
            </div>
            <div
              className="w-1/3 aspect-[8/9] shadow-card bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage:
                  "url(https://www.urimat.com/fileadmin/produkte/fx-m/waschtisch_quadro_minarit_fuer_fx_m_urimat.png)",
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-primary text-3xl font-light ">
            The technology of waterless urinals
          </div>
          <div className="flex w-full gap-6">
            <div className="w-2/3 flex flex-col gap-8">
              <div className="text-base text-txtTT flex flex-col gap-4">
                <div className="w-full list-decimal font-extralight">
                  A hand dryer is an electrical device for drying hands in the
                  sanitary room by means of a generated cold or warm air flow.
                  The integrated motor draws in the ambient air via a blower and
                  then blows the heated air back via a germ filter (HEPA filter)
                  into the air duct for drying hands. The function is triggered
                  by a sensor to which you hold your hands. The warm air that
                  flows out of the hand dryer ensures dry hands within a few
                  seconds by simply evaporating the moisture - without the use
                  of paper or cloth towels.
                </div>
              </div>
            </div>
            <div
              className="w-1/3 aspect-[8/9] shadow-card bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage:
                  "url(https://www.urimat.com/fileadmin/_processed_/4/0/csm_img_20211118_110624_5f2fb0bbf2.jpg)",
              }}
            />
          </div>
        </div>
        <div className="flex justify-center gap-6">
          <div className="text-txtTT font-light w-1/2 flex flex-col gap-4">
            <div className="text-primary text-3xl font-light">New design</div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
          </div>
          <div className="text-txtTT font-light w-1/2 bg-white shadow-card rounded-tl-3xl rounded-br-3xl flex flex-col justify-between">
            <div className="p-6 flex flex-col gap-3">
              <div className=" text-primary text-3xl font-light">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div
              className="w-full h-[65%] bg-cover bg-center bg-no-repeat rounded-br-3xl"
              style={{
                backgroundImage: `url("https://www.urimat.com/fileadmin/user_upload/nordisch_berg_bild_postkarte.png")`,
              }}
            />
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
