import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

export default function Layout(props) {
  const [openProducts, setOpenProducts] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const [openAboutUs, setOpenAboutUs] = useState(false);
  const handleOpenChangeProducts = (flag) => {
    setOpenProducts(flag);
  };
  const handleOpenChangeServices = (flag) => {
    setOpenServices(flag);
  };
  const handleOpenChangeAboutUs = (flag) => {
    setOpenAboutUs(flag);
  };
  const productsItem = [
    { label: <Link to="/products/urinals">Усгүй шээлтүүр</Link>, key: "Urinals" },
    {
      label: <Link to="/products/hand-dryers">Гар хатаагч</Link>,
      key: "HandDryers",
    },
    {
      label: <Link to="/products/mb-activetrap">MB-Идэвхитэй цэвэрлэгээний шингэн</Link>,
      key: "MBActiveTrap",
    },
    {
      label: <Link to="/products/biological-cleaner">Байгалын гаралтай цэвэрлэгч</Link>,
      key: "BiologicalCleaner",
    },
    // {
    //   label: (
    //     <Link to="/products/fx-m-washbasin-modular">
    //       FX-M Washbasin Modular
    //     </Link>
    //   ),
    //   key: "FXMWashbasinModular",
    // },
    // {
    //   label: <Link to="/products/partition-walls">Partition Walls</Link>,
    //   key: "PartitionWalls",
    // },
    // {
    //   label: (
    //     <Link to="/products/in-wall-mounting-frame">
    //       In Wall Mounting Frame
    //     </Link>
    //   ),
    //   key: "InWallMountingFrame",
    // },
    {
      label: <Link to="/products/urimat-connect">URIMAT дэлгэцтэй</Link>,
      key: "UrimatConnect",
    },
    // {
    //   label: <Link to="/products/disinfection">Disinfection</Link>,
    //   key: "Disinfection",
    // },
  ];
  const servicesItem = [
    { label: <Link to="/services"> Товч танилцуулга </Link>, key: "Overview" },
    {
      label: (
        <Link to="/services/instruction-videos"> Видео танилцуулга </Link>
      ),
      key: "Video Instructions",
    },
    // {
    //   label: <Link to="/services/news-updates"> News & Updates </Link>,
    //   key: "News & Updates",
    // },
    // {
    //   label: <Link to="/services/downloads"> Downloads </Link>,
    //   key: "Downloads",
    // },
    { label: <Link to="/services/savings-1"> Хэмнэлт </Link>, key: "Savings" },
    {
      label: <Link to="/services/faq"> Түгээмэл асуултууд (FAQ) </Link>,
      key: "Frequently Asked Questions (FAQ)",
    },
  ];
  const aboutUsItems = [
    { label: <Link to="/about-us">Товч танилцуулга</Link>, key: "Overview" },
    // {
    //   label: <Link to="/about-us/guiding-principles">Guiding principles</Link>,
    //   key: "Guiding principles",
    // },
    // {
    //   label: <Link to="/about-us/references">References</Link>,
    //   key: "References",
    // },
  ];
  return (
    <>
      <div
        className={`bg-bgPrimary  flex flex-col h-screen w-screen select-none items-center overflow-y-hidden font-sans`}
      >
        <div className="absolute left-0 top-40 flex-col">
          <a href="https://www.facebook.com/profile.php?id=61553409655748"
            className="h-12 w-12 bg-fb hover:w-32 flex text-transparent hover:text-white items-center justify-evently rounded-tr-xl hover:rounded-br-xl hover:justify-around pl-3 hover:pl-0"
            data-network="facebook">
            <img className="h-6 w-6" alt="facebook sharing button" src="https://platform-cdn.sharethis.com/img/facebook.svg"/>
            Facebook
          </a>
          {/* <a
            href="/"
            className="h-12 w-12 bg-twitter hover:w-32 flex text-transparent hover:text-white items-center justify-evently hover:rounded-tr-xl hover:rounded-br-xl hover:justify-around pl-3 hover:pl-0"
            data-network="twitter"
          >
            <img
              className="h-6 w-6"
              alt="twitter sharing button"
              src="https://platform-cdn.sharethis.com/img/twitter.svg"
            />
            Twitter
          </a>
          <a
            href="/"
            className="h-12 w-12 bg-linkedIn hover:w-32 flex text-transparent hover:text-white items-center justify-evently rounded-br-xl hover:rounded-tr-xl hover:justify-around pl-3 hover:pl-0"
            data-network="linkedin"
          >
            <img
              className="h-6 w-6"
              alt="linkedin sharing button"
              src="https://platform-cdn.sharethis.com/img/linkedin.svg"
            />
            Linkedin
          </a> */}
        </div>
        <div
          style={{ zIndex: 1030 }}
          className={`absolute top-0 gap-2 rounded-br-3xl rounded-bl-3xl shadow-xl px-8 w-[60%] h-24 flex flex-row items-center justify-between py-3 bg-white`}
        >
          <Link
            to="/"
            className="w-1/5 h-10 bg-no-repeat bg-center bg-contain"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/typo3conf/ext/threeme/Resources/Public/assets/img/urimat-logo-ch.svg)",
            }}
          />
          <div
            className={`w-[50%] h-full flex flex-row items-center justify-between py-3`}
          >
            <Dropdown
              menu={{
                items: productsItem,
              }}
              onOpenChange={handleOpenChangeProducts}
              open={openProducts}
            >
              <Link to="/products">
                <Space>
                  Бүтээгдэхүүн
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
            <Dropdown
              menu={{
                items: servicesItem,
              }}
              onOpenChange={handleOpenChangeServices}
              open={openServices}
            >
              <Link to="/services">
                <Space>
                  Үйлчилгээ
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
            <Dropdown
              menu={{
                items: aboutUsItems,
              }}
              onOpenChange={handleOpenChangeAboutUs}
              open={openAboutUs}
            >
              <Link to="/about-us">
                <Space>
                  Бидний тухай
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
            {/* <Link to="/">
              <Space>Холбоо барих</Space>
            </Link> */}
            <Link to="">
              <Space> </Space>
            </Link>
          </div>
        </div>
        <div className="w-full h-full">
          <Outlet />
        </div>
      </div>
    </>
  );
}
