import React from "react";
import { Link } from "react-router-dom";

const CoreFooter = (props) => {
  return (
    <div
      className="w-full min-h-[65%] bg-cover bg-top bg-no-repeat p-6 overflow-y-auto flex justify-center items-end"
      style={{
        backgroundImage: `url("	https://www.urimat.com/typo3conf/ext/threeme/Resources/Public/assets/img/footer-bg-ch.jpg")`,
      }}
    >
      <div className="bg-white w-[60%]  rounded-tl-3xl rounded-br-3xl  p-8 flex flex-col gap-6">
        <div className="flex justify-between">
          <Link to="/">
            <div
              className="w-40 h-10 bg-no-repeat bg-center bg-contain"
              style={{
                backgroundImage:
                  "url(https://www.urimat.com/typo3conf/ext/threeme/Resources/Public/assets/img/urimat-logo-ch.svg)",
              }}
            />
          </Link>
          <div className="flex flex-col items-end">
            <div className="font-semibold text-black">+976 99 69 68 85</div>
            <div className="font-extralight text-primary">
              <a href="mailto:info@urimatmongolia.com">info@urimatmongolia.com</a>
              </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-400 rounded-full" />
        <div className="flex flex-col">
          <div className="flex justify-between items-center">
            <div className="font-semibold text-txtTT">
              Gran Via Empresa LLC, 
              <span className="font-extralight">
              Unur Khoroolol, SonginoKhairkhan district, Ulaanbaatar Mongolia 18031-0022
              </span>
            </div>
            <div className=" flex text-txtTT font-light text-xs gap-14">
              {/* <div>Data privacy</div>
              <div>Legal notice</div>
              <div>Terms & Conditions</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CoreFooter };
