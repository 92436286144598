import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function Overview(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-cover bg-center bg-no-repeat p-6 overflow-y-auto flex justify-center items-end text-white gap-8"
        style={{
          backgroundImage: `linear-gradient( hsla(202, 100%, 30%, 0.8), hsla(202, 100%, 35%, 0.8) ),
          url("https://www.urimat.com/fileadmin/_processed_/a/0/csm_service_wagen2a_d9b3a0219a.jpg")`,
        }}
      >
        <div className="w-1/3 h-2/3 font-bold text-3xl text-center">
          URIMAT үйлчилгээ
        </div>
        <div className="w-1/3 h-2/3 text-3xl ">
          URIMAT усгүй шээлтүүр нь хамгийн өндөр чанарын стандартыг санал болгодог. Бид URIMAT-ын бүх бүтээгдэхүүний бүрэн найдвартай байдлыг баталгаажуулна.
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center my-16">
        <div className="flex flex-col w-[60%] items-center  top-12 gap-16">
          <div className="flex justify-center px-6 gap-6 text-secondary text-3xl">
            Бид Швейцарийн URIMAT усгүй шээлтүүр хэрэглэгчиддээ ажиллагааг хангахын тулд дараах үйлчилгээг санал болгож байна.
          </div>
          <div className="flex gap-8 w-full">
            <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-betweenitems-center font-light gap-6">
              <h2 className="text-primary font-normal font-sans text-2xl">
                URIMAT
              </h2>
              <h2 className="text-primary font-light font-sans text-2xl">
                бүрэн үйлчилгээний захиалга
              </h2>
              <p>
                URIMAT-ийн бүрэн үйлчилгээний захиалгын тусламжтайгаар бид MB-ActiveTraps-ийг цаг тухайд нь солих, мөн URIMAT системд бүрэн засвар үйлчилгээ хийх, түүний дотор ус зайлуулах хоолойг угаах зэрэг үйлчилгээг баталгаажуулна. URIMAT-ийн бүрэн үйлчилгээний захиалгын хүрээнд манай үйлчилгээний мэргэжилтнүүд тохиролцсоны дагуу жилийн турш газар дээр нь үйлчилгээний ажлыг гүйцэтгэнэ.
              </p>
              <p>
                Уримат-ийн бүрэн үйлчилгээний захиалга нь дараах үйлчилгээнүүдийг агуулна:
              </p>

              <ul className="text-txtTT flex flex-col gap-2 list-disc list-inside">
                <li>Хүрч очих</li>
                <li>Үйлчилгээг богино хугацаанд гүйцэтгэх</li>
                <li>Уримат системийг цэвэрлэх</li>
                <li>Хоолойг угаах</li>
                <li>Үнэр баригч MB-ActiveTrap солих</li>
                <li>Уримат системийн ажиллагааг шалгах</li>
                <li>
                  Дагалдах хэрэгсэл, Жишээлбэл : цэвэрлэгээний бодисыг шуудангийн зардалгүйгээр мэргэжилтэнгүүдээс шууд авах боломжтой.
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-betweenitems-center font-light gap-6">
              <h2 className="text-primary font-normal font-sans text-2xl">
                URIMAT
              </h2>
              <h2 className="text-primary font-light font-sans text-2xl">
                нийлүүлэлтийн гэрээ
              </h2>
              <p>
                Гран Виа Эмпрэса ХХК нь таныг өдөр тутмын ажилдаа бүрэн төвлөрүүлэх боломжийг олгох зорилгоор УРИМАТ нийлүүлэлтийн гэрээг санал болгож байна. УРИМАТ дагалдах хэрэгслийг захиалах талаар санаа зовох шаардлагагүй, бид MB-ActiveTraps-ийг жилийн нэг удаагийн нийлүүлэлтээр тусгай үнээр, нэмэлт захиалгагүйгээр, цаг тухайд нь, хүргэлтийн зардалгүйгээр хүргэх болно.
              </p>
              <p>
                URIMAT нийлүүлэлтийн гэрээнд дараахь үйлчилгээ орно.
              </p>

              <ul className="text-txtTT flex flex-col gap-2 list-disc list-inside">
                <li>MB-ActiveTraps-ийн жилийн нийлүүлэлт</li>
                <li>Дэлгэрэнгүй заавар</li>
                <li>Ашигласан оруулгыг сугалахад зориулсан бариул</li>
                <li>Хүргэлт үнэгүй</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
