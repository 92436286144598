import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function VideoInstructions(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center pt-16 overflow-y-auto">
      <div className="flex flex-col w-[60%] items-center gap-16 my-16 pt-8">
        <div className="w-full text-primary text-5xl font-light">
          Видео танилцуулга
        </div>
        <div className="flex flex-col gap-8 w-full">
          <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
            <div className="w-1/2 gap-4 flex flex-col">
              <div className="text-primary text-4xl font-light">
                Уримат цэвэрлэх явц
              </div>
              <div className="font-light text-txtTT">
                {/* Your URIMAT is clean in just a few easy steps. */}
              </div>
            </div>
            <iframe
              className="w-1/2 aspect-[16/9]"
              src="https://www.youtube.com/embed/lN9m2LQ8Jhg"
              title="URIMAT urinal maintenance"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
            <div className="w-1/2 gap-4 flex flex-col">
              <div className="text-primary text-4xl font-light">
                Уримат ActiveTrap шинээр солих заавар
              </div>
              <div className="font-light text-txtTT">
                {/* Your URIMAT is clean in just a few easy steps. */}
              </div>
            </div>
            <iframe
              className="w-1/2 aspect-[16/9]"
              src="https://www.youtube.com/embed/qH_7x0lcqqc"
              title="URIMAT odour trap exchange"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
            <div className="w-1/2 gap-4 flex flex-col">
              <div className="text-primary text-4xl font-light">
                Уримат суурилуулалтын заавар
              </div>
              <div className="font-light text-txtTT">
                {/* Your URIMAT is clean in just a few easy steps. */}
              </div>
            </div>
            <iframe
              className="w-1/2 aspect-[16/9]"
              src="https://www.youtube.com/embed/CzLv66bU1Co"
              title="URIMAT eco Installation waterless urinal"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          {/* <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
            <div className="w-1/2 gap-4 flex flex-col">
              <div className="text-primary text-4xl font-light">
                URIMAT cleaning instructions
              </div>
              <div className="font-light text-txtTT">
                Your URIMAT is clean in just a few easy steps.
              </div>
            </div> */}
            {/* <iframe
              className="w-1/2 aspect-[16/9]"
              src="https://www.youtube.com/embed/DVrkd5hMHW4"
              title="Installation of FX M modular washbasin unit URIMAT"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            /> */}
          {/* </div> */}
          <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
            <div className="w-1/2 gap-4 flex flex-col">
              <div className="text-primary text-4xl font-light">
                Гар хатаагч (Favorit) суурилуулах заавар
              </div>
              <div className="font-light text-txtTT">
                {/* Your URIMAT is clean in just a few easy steps. */}
              </div>
            </div>
            <iframe
              className="w-1/2 aspect-[16/9]"
              src="https://www.youtube.com/embed/NpAwGZWb4vQ"
              title="URIMAT Favorit"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
            <div className="w-1/2 gap-4 flex flex-col">
              <div className="text-primary text-4xl font-light">
                Гар хатаагч (Champion pro) суурилуулах заавар
              </div>
              <div className="font-light text-txtTT">
                {/* Your URIMAT is clean in just a few easy steps. */}
              </div>
            </div>
            <iframe
              className="w-1/2 aspect-[16/9]"
              src="https://www.youtube.com/embed/2DjNMUlWk90"
              title="URIMAT Champion Pro"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
