import React, { useState } from "react";
import { ItemCard3 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

export default function UrimatConnect(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div className="w-full min-h-[65%] bg-gray-200 gap-8 overflow-y-auto flex items-center">
        <div
          className="w-1/2 h-full bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("https://www.urimat.com/fileadmin/produkte/media/header-werbung-urimat.png")`,
          }}
        />
        <div className="w-1/3 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            URIMAT-тай сурталчилгаа
            40 секундын бүрэн анхаарал
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Та болон таны үйлчлүүлэгчдэд зориулсан орчин үеийн, зорилтот, үр дүнтэй сурталчилгааны хэрэгсэл.  Манай дижитал (DOOH) сүлжээг ашигласнаар та сард 450,000 хүнд ямар ч үрэлгэн байдалгүйгээр хүрч чадна.
          </div>
        </div>
      </div>
      <div className="w-[60%] flex flex-col items-center justify-center top-12 gap-16 my-16">
        {/* <Link
          to="/"
          className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 -mt-24 flex justify-center items-center p-6 h-14 bg-green-500 uppercase"
        >
          <ArrowRightOutlined />
          Advertising enquiry
        </Link> */}
        <div className="flex justify-center gap-6">
          <div className="text-txtTT font-light w-1/2 flex flex-col gap-4">
            <div className="text-primary text-3xl font-light">
              URIMAT-тай DOOH
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              URIMAT нь тодорхой зорилтот бүлэгт өргөн хүрээтэй сурталчилгааны хэрэгслийг санал болгодог. Сувгийг өөрчлөх эсвэл хуудсыг эргүүлэх боломжгүй. Таны зар дунджаар 40 секундын турш идэвхитэй ажиглагддаг.
              Загвараас хамааран URIMAT шээлтүүр нь цаасан эсвэл LCD дэлгэцийг санал болгодог бөгөөд энэ нь таны зорилтот бүлэгт дүрст сурталчилгааг хүргэдэг.
            </div>
            <div className="text-txtTT font-light flex flex-col gap-3">
              <div className=" font-normal">Ашиг тус:</div>
              <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
                <li>40 секундын бүрэн анхаарал</li>
                <li>Тархалтын алдагдал байхгүй</li>
                <li>Зар сурталчилгааны орлого/санхүүжилт</li>
                <li>Эко маркетинг</li>
              </ul>
            </div>
          </div>
          <div className="text-txtTT font-light w-1/2 bg-white shadow-card rounded-tl-3xl rounded-br-3xl flex flex-col justify-between">
            <div className="p-6 flex flex-col gap-3">
              <div className=" text-primary text-3xl font-light">
                40 секундын бүрэн анхаарал
              </div>
                Дэлгэцийг сурталчилгааны талбай болгон түрээслээд орлогоо нэмэгдүүлээрэй. Бусад сурталчилгааны бүтээгдэхүүнээс хавьгүй илүү хүлээн зөвшөөрөгдсөн орчин үеийн сурталчилгааны өвөрмөц хэлбэр юм.
                <li>93% нь шээлтүүр дээрхи зарыг анзаардаг</li>
                <li>96% үйлчлүүлэгчид шээлтүүр дээрхи сурталчилгаанд эерэг хандлага гаргадаг</li>
                <li>90% үйлчлүүлэгчид зар сурталчилгааны утга санааг санаж, хайлт хийж байдаг</li>
            </div>
            <div
              className="w-full h-[65%] bg-contain bg-center bg-no-repeat rounded-br-3xl"
              // style={{
              //   backgroundImage: `url("https://www.urimat.com/fileadmin/_processed_/2/d/csm_english_befragung_connect_c8343c507e.png")`,
              // }}
            />
          </div>
        </div>
        {/* <div className="flex gap-8 w-full">
          <div className="flex flex-col gap-8 w-2/3">
            <div className="text-primary text-3xl font-light ">
              Digital advertising display
            </div>
            <div className="flex w-full gap-4">
              <div className="w-1/2 text-txtTT font-light flex flex-col gap-3">
                <div className=" font-normal">Benefits</div>
                <div className="text-txtTT font-light flex flex-col gap-3">
                  URIMAT also offers an advertising medium with a wide reach in
                  a clearly defined target group. It’s not possible to change
                  the channel or turn the page. Your message is observed for an
                  average of 40 seconds. Depending on the model, the URIMAT
                  urinals offer passive or LCD displays, which bring animated
                  messages to your male target group.
                </div>
              </div>
              <div className="w-1/2 text-txtTT font-light flex flex-col gap-3">
                <div className=" font-normal">Benefits</div>
                <div className="text-txtTT font-light flex flex-col gap-3">
                  URIMAT also offers an advertising medium with a wide reach in
                  a clearly defined target group. It’s not possible to change
                  the channel or turn the page. Your message is observed for an
                  average of 40 seconds. Depending on the model, the URIMAT
                  urinals offer passive or LCD displays, which bring animated
                  messages to your male target group.
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-card relative w-1/3 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center pb-20">
            <div className="flex flex-col gap-4">
              <h2 className="text-primary font-normal font-sans text-2xl card-title">
                Documents
              </h2>
              <div className="flex  w-full gap-4 h-40">
                <div
                  className=" bg-no-repeat bg-contain bg-bottom h-full aspect-[12/16]"
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                  }}
                />
                <div className="font-light">
                  Product Brochure URIMAT hand dryer
                </div>
              </div>
              <div className="flex w-full gap-4 h-40">
                <div
                  className=" bg-no-repeat bg-contain bg-bottom h-full aspect-[12/16]"
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                  }}
                />
                <div className="font-light">
                  Product Brochure URIMAT hand dryer
                </div>
              </div>
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
        </div> */}
        {/* <div className="flex flex-col justify-center gap-6"> */}
          {/* <div className="text-primary text-3xl font-light "> */}
            {/* Digital advertising display */}
          {/* </div> */}
          {/* <div className="flex justify-center gap-6"> */}
            {/* <div className="text-txtTT font-light w-1/4"> */}
              {/* <div className=" font-normal">New design</div> */}
              {/* You will love our URIMAT hand dryers and their attractive design. */}
            {/* </div> */}
            {/* <div className="text-txtTT font-light w-1/4"> */}
              {/* <div className=" font-normal"> */}
                {/* Low-cost and environmentally friendly */}
              {/* </div> */}
              {/* Our hand dryers not only protect the environment, but also help to */}
              {/* save costs. No storage, no disposal of paper and fabric towels. */}
              {/* The energy requirements of our hand dryer are significantly lower */}
              {/* than those of paper and fabric towel dispensers. */}
            {/* </div> */}
            {/* <div className="text-txtTT font-light w-1/4"> */}
              {/* <div className=" font-normal">Absolute hygiene </div> */}
              {/* With their contactless, sensor-controlled operation, our hand */}
              {/* dryers are based on a clean technology. */}
            {/* </div> */}
            {/* <div className="text-txtTT font-light w-1/4"> */}
              {/* <div className=" font-normal">Engine</div> */}
              {/* With the brushless electric motor, there are no brushes (sliding */}
              {/* contacts), hence there is no mechanical wear. This type of motor */}
              {/* lasts significantly longer. Step regulation and hot and cold air */}
              {/* operation are possible. */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
        {/* <div className="flex justify-between gap-6 w-full">
          <div
            className="text-txtTT font-light w-1/4 bg-contain bg-center bg-no-repeat aspect-[1/1] h-36"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/fileadmin/user_upload/img3.png)",
            }}
          />
          <div
            className="text-txtTT font-light w-1/4 bg-contain bg-center bg-no-repeat aspect-[1/1] h-36"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/fileadmin/user_upload/img4.png)",
            }}
          />
          <div
            className="text-txtTT font-light w-1/4 bg-contain bg-center bg-no-repeat aspect-[1/1] h-36"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/fileadmin/_processed_/4/3/csm_img5_ac4050aa54.png)",
            }}
          />
          <div
            className="text-txtTT font-light w-1/4 bg-contain bg-center bg-no-repeat aspect-[1/1] h-36"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/fileadmin/user_upload/img6.png)",
            }}
          />
        </div> */}
        <div className="w-full flex flex-col justify-between">
          {/* <div className="text-primary text-3xl font-light ">
            URIMAT DOOH-System: Benefits include
          </div> */}
          <div className="w-full flex justify-between">
            {/* <div className="flex flex-col 2-1/2 gap-3 p-6">
              <div className="text-base text-txtTT flex flex-col gap-4">
                <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
                  <li>Environmentally friendly</li>
                  <li>Cost-effective</li>
                  <li>Quick and efficient</li>
                  <li>Simple installation</li>
                  <li>Robust & durable</li>
                </ul>
              </div>
            </div> */}
            {/* <video controls className="w-1/2 aspect-[16/9] rounded-br-3xl"> */}
              {/* <source
                src="https://www.urimat.com/fileadmin/user_upload/installation_of_fx-m_modular_washbasin_unit_urimat.mp4"
                type="video/mp4"
              /> */}
            {/* </video> */}
          </div>
        </div>
        {/* <div className="text-primary text-3xl font-light w-full">
          Our DOOH-Network in Switzerland
        </div>
        <div className="w-full"></div> */}
      </div>
      <CoreFooter />
    </div>
  );
}
