import React from "react";
import { Link } from "react-router-dom";
import { CoreFooter } from "../../components/Footer";

export default function GuidingPrinciples(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-cover bg-left bg-no-repeat p-6 overflow-y-auto flex justify-center items-center gap-24 text-white px-[20%]"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/_processed_/d/c/csm_bergnebel-1920x1080_e8f5421bdb.jpg")`,
        }}
      >
        <div className="text-5xl font-bold w-1/2 text-end h-[50%]">
          Our guiding principles
        </div>
        <div className="w-1/2 flex flex-col gap-4 h-[50%]">
          <div className="text-3xl font-light">
            Dry your hands in 9-11 seconds. Quiet, fast and environmentally
            friendly.
          </div>
          <ul className="flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
        </div>
      </div>
      <div className="w-[60%] flex flex-col items-center justify-center top-12 gap-16 my-16">
        <div className="flex justify-center px-6 gap-6">
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-light text-primary text-4xl">Customer</div>
            <ul className="list-disc list-inside">
              <li> We work hard to earn the trust of all of our customers. </li>
            </ul>
          </div>
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-light text-primary text-4xl">Partners</div>
            <ul className="list-disc list-inside">
              <li> We rely on partnerships that offer mutual benefits</li>
              <li>
                Our collaboration is straightforward and is shaped by fairness
              </li>
            </ul>
          </div>
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-light text-primary text-4xl">Public</div>
            <ul className="list-disc list-inside">
              <li> We communicate openly and transparently</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center px-6 gap-6">
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-light text-primary text-4xl">Environment</div>
            <ul className="list-disc list-inside">
              <li> We work hard to earn the trust of all of our customers. </li>
            </ul>
          </div>
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-light text-primary text-4xl">Employees</div>
            <ul className="list-disc list-inside">
              <li> We rely on partnerships that offer mutual benefits</li>
              <li>
                Our collaboration is straightforward and is shaped by fairness
              </li>
              <li>
                Our collaboration is straightforward and is shaped by fairness
              </li>
            </ul>
          </div>
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-light text-primary text-4xl">Suppliers</div>
            <ul className="list-disc list-inside">
              <li> We communicate openly and transparently</li>
            </ul>
          </div>
        </div>
        <div className="flex gap-8 w-full">
          <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
            <div className="flex flex-col text-txtTT gap-4 justify-between ">
              <h2 className="font-normal text-primary font-sans text-3xl card-title">
                Quality Policy
              </h2>
              <div className="font-light h-2/3">
                Our Quality Policy summarises the essential elements of our
                commitment for excellence.
              </div>
            </div>
            <Link
              to="https://www.urimat.com/fileadmin/ueber-uns/urimat_quality_policy_en_01.pdf"
              className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
              style={{
                backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/0/2/csm_urimat_quality_policy_en_01_72c74e23e5.png)`,
              }}
            />
          </div>
          <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
            <div className="flex flex-col text-txtTT gap-4 justify-between ">
              <h2 className="font-normal text-primary font-sans text-3xl card-title">
                Sustainability Statement
              </h2>
              <div className="font-light h-2/3">
                We consider sustainability to be an integral part of all that we
                do.
              </div>
            </div>
            <Link
              to="https://www.urimat.com/fileadmin/ueber-uns/urimat_sustainability_statement_en_01.pdf"
              className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
              style={{
                backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/3/4/csm_urimat_sustainability_statement_en_01_c097bffe88.png)`,
              }}
            />
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
