import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function FAQ(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center pt-16 overflow-y-auto">
      <div className="flex flex-col w-[60%] items-center gap-16 my-16 pt-8">
        <div className="w-full text-primary text-5xl font-light">
          Түгээмэл асуултууд (FAQ)
        </div>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full flex flex-col gap-3">
            <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
              <div className="w-11/12 gap-4 flex flex-col">
                <div className="text-primary text-4xl font-light">
                  Мембран хавхлагатай  үнэр баригч
                </div>
              </div>
              <div
                className="w-1/12 aspect-[9/16] bg-no-repeat bg-contain bg-center"
                style={{
                  backgroundImage:
                    "url(https://www.urimat.com/fileadmin/_processed_/4/0/csm_wasserloses_urinal_ecovideo_urimat_0c273f3e0d.png)",
                }}
              />
            </div>
            <div className="font-light text-txtTT text-xl">
              MB-ActiveTrap-ийн хэр удаан ашиглах вэ?
            </div>
            <div className="font-extralight">
              Усгүй шээлтүүр урхины ашиглалтын хугацаа дунджаар 7000-8000 удаа байдаг. Үүний дараа цэвэрлэх шоо бүрэн уусч, хавхны улаан өнгийн тавцан харагдана. Дараа нь хаалтыг шинээр солих шаардлагатай.
            </div>
            {/* <div className="font-light text-txtTT text-xl"> */}
              {/* How can I replace the MB-ActiveTrap? */}
            {/* </div> */}
            {/* <div className="font-extralight"> */}
              {/* The trap of the waterless urinal can be easily replaced. Please */}
              {/* watch the replacement instruction video: */}
              {/* <a href="/services/instruction-videos" className="text-primary"> */}
                {/* Trap-Replacement */}
              {/* </a> */}
            {/* </div> */}
            <div className="font-light text-txtTT text-xl">
              Бохир усны хоолойд шээсний бохирдол хуримтлагдаж байна уу?
            </div>
            <div className="font-extralight">
              Шээс нь цэвэр устай нийлсэнээр химийн урвал үүсдэг. Усанд (ихэвчлэн саарал ус) ууссан шохой болон бусад ууссан бодисууд нь шээс, шээсний хүчилтэй нийлж, уусдаггүй шохой-магнийн нэгдэл үүсгэдэг.
              Усгүй шээлтүүрэнд ус хэрэглэдэггүй тул шээсний царцдас үүсдэггүй. Усгүй шээлтүүрийн нүхэнд шээсний лаг үүсч болох ч, үйлчилгээ хийх үед усаар амархан арилдаг.
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүрээс үнэр үнэртдэг үү?
            </div>
            <div className="font-extralight">
              Үгүй, усгүй шээлтүүр нь үнэр гаргахгүй.{" "}
              <span className="font-normal">
                Ариун цэврийн өрөөнд эвгүй үнэр гарах таван үндсэн шалтгаан бий:
              </span>
              <ul className="text-txtTT flex flex-col gap-3 list-decimal list-inside p-4">
                <li>Шал эсвэл хананд шээсний үлдэгдэл</li>
                <li>Өрөөний агааржуулалт хангалтгүй</li>
                <li>Шалны ус зайлуулах хоолой</li>
                <li>Шалны үе ба булангуудыг цэвэрлээгүй</li>
                <li>Хамгийн чухал нь: Засвар үйлчилгээ доголдсон</li>
              </ul>
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүр яаж ажилладаг вэ?
            </div>
            <div className="font-extralight">
              The technology and functionality is explained on the following
              link:
              <a href="/products/urinals" className="text-primary">
                Усгүй технологи
              </a>
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүр нь эрүүл ахуйн шаардлага хангасан уу?
            </div>
            <div className="font-extralight">
              Усгүй шээлтүүр  нь угаалгын өрөөний эрүүл ахуйг сайжруулдаг. Ус байхгүй тул нян үүсэх нь бага байдаг. Судалгаанаас харахад усгүй шээсэнд колони үүсгэдэг нэгжүүд нь усаар урсгасан шээстэй харьцуулахад цөөн байдаг.
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүрийг усаар урсгасан шээстэй харьцуулахад хэр их цэвэрлэгээ/засвар хийх шаардлагатай вэ?
            </div>
            <div className="font-extralight">
              Усгүй шээлтүүрийг энгийн усаар урсгадаг бие засах газартай харьцуулахад цэвэрлэх, засвар үйлчилгээ хийх нь илүү хялбар, хурдан бөгөөд учир нь нян, шохойн хуримтлал үүсэх боломжтой өнцөг байхгүй загвартай хийгдсэн. 
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүрийн ачаар та ямар зардал хэмнэх вэ?
            </div>
            <div className="font-extralight">
              Усгүй шээлтүүр нь усаар урсгадаг шээлтүүртэй харьцуулахад өртөг өндөр, урсгах электрон төхөөрөмж/мэдрэгчийн технологи шаарддаггүй тул хөрөнгө оруулалтын зардал бага байна. Үүнээс гадна усгүй шээлтүүрийг  ажиллуулахдаа тог болон бохир усны зардлыг хэмнэдэг. 
              <a href="/services/savings-1" className="text-primary">
                Та эдгээр зардлын хэмнэлтийг ХЭМНЭЛТ цэснээс тооцоолж болно.
              </a>
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүрийг суурилуулахад хэр хугацаа шаардагдах вэ?
            </div>
            <div className="font-extralight">
              Усгүй шээлтүүрийг  20 минут хүрэхгүй хугацаанд суурилуулдаг. Энэ нь уламжлалт усаар угаадаг шээлтүүрийг суурилуулахад зарцуулсан хугацааны талаас бага хугацаа юм.
              Усгүй шээлтүүрийг суулгахад хялбар байдаг. Угаах мэдрэгч шаардлагагүй тул LCD дэлгэцтэй эсвэл өөрөө өөрийгөө цэвэрлэх функцтэй загваруудаас бусад тохиолдолд цахилгаан шаардлагагүй болно.
            </div>
            <div className="font-light text-txtTT text-xl">
              Усгүй шээлтүүр нь урсгал зардлыг бууруулдаг уу?
            </div>
            <div className="font-extralight">
              Усгүй шээлтүүр нь таны усны төлбөрийг эрс багасгадаг. Усны зардлаас шалтгаалаад усгүй шээлтүүр 10-18 сарын дотор (м3 усанд 6000₮+) зардлаа нөхдөг. Та Монгол оронд ашиглах нь энэ хугацаа уртсах хэдий ч удаан хугацааны хөрөнгө оруулалт болж 20-48 сарын дотор хөрөнгө оруулалтаа хэмнэж үүнээс цааших бохир болон тогны мөнгөний хэмнэлт нь таны цэвэр ашиг болно.
              {/* <a href="/about-us/references" className="text-primary">
                References
              </a> */}
            </div>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
              <div className="w-11/12 gap-4 flex flex-col">
                <div className="text-primary text-4xl font-light">
                  Biological Sanitary Cleaner
                </div>
              </div>
              <div
                className="w-1/12 aspect-[9/16] bg-no-repeat bg-contain bg-center"
                style={{
                  backgroundImage:
                    "url(https://www.urimat.com/fileadmin/produkte/reinigungsmittel/flasche_1l_u_spruehflasche.png)",
                }}
              />
            </div>
            <div className="font-light text-txtTT text-xl">
              Is MB-ActiveCleaner ecological?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner is label-free and enables urinals to be cleaned
              without the use of hazardous and harmful chemicals.
            </div>
            <div className="font-light text-txtTT text-xl">
              Does MB-ActiveCleaner eliminate bad odours?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner removes bad odors that arise when organic
              substances are broken down. It is particularly suitable for
              maintenance cleaning and odor removal in heavily used sanitary
              facilities.
            </div>
            <div className="font-light text-txtTT text-xl">
              Are biological cleaners more expensive?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner offers a very good price-performance ratio, is
              highly concentrated and extremely economical. The biological
              sanitary cleaner is mostly cheaper than conventional sanitary
              cleaners.
            </div>
            <div className="font-light text-txtTT text-xl">
              Is MB-ActiveCleaner skin-friendly?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner has been dermatologically tested for skin
              compatibility and rated as very good.
            </div>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
              <div className="w-11/12 gap-4 flex flex-col">
                <div className="text-primary text-4xl font-light">
                  Гар хатаагч
                </div>
              </div>
              <div
                className="w-1/12 aspect-[9/16] bg-no-repeat bg-contain bg-center"
                style={{
                  backgroundImage:
                    "url(https://www.urimat.com/fileadmin/produkte/handdryer/handdryer-favorit-weiss-seite.png)",
                }}
              />
            </div>
            <div className="font-light text-txtTT text-xl">
              Гар хатаагчийн эрчим хүчний хэрэгцээ хэр өндөр вэ?
            </div>
            <div className="font-extralight">
              Эрчим хүчний хэрэгцээ нь цаас, даавуун алчуур үйлдвэрлэх явцтай харьцуулахад зуу дахин бага байдаг.
            </div>
            <div className="font-light text-txtTT text-xl">
              Гар хатаагч эрүүл ахуйн шаардлага хангасан уу?
            </div>
            <div className="font-extralight">
              Товч дарах шаардлагүй, мэдрэгчээр удирддаг хэрэглээний ачаар манай бүх гар хатаагч нь эрүүл ахуйн шаардлага хангасан бөгөөд таны эрүүл мэндэд сөрөг нөлөөгүй. Эрүүл ахуйг дээд зэргээр хангах үүднээс бүх Уримат гар хатаагч нь угаалгын өрөөний агаар дахь бүх шороо тоосонцор, бактерийг  гарт хүрэхээс тань өмнө 99.9%-ийг устгадаг сольж болдог HEPA шүүлтүүрээр стандартын дагуу тоноглогдсон.
            </div>
            <div className="font-light text-txtTT text-xl">
              Гар хатаагч нь байгаль орчинд ээлтэй юу?
            </div>
            <div className="font-extralight">
              Манай гар хатаагч нь байгаль орчныг хамгаалахаас гадна зардлыг хэмнэхэд тусалдаг. Цаас, даавуун алчуурыг хадгалах, хаях шаардлагагүй.
            </div>
            <div className="font-light text-txtTT text-xl">
              Уримат гар хатаагчийн ашиглалтын хугацаа хэд вэ?
            </div>
            <div className="font-extralight">
              Сойзгүй цахилгаан мотортой (гулсах контакт) тул механик үрэлт байхгүй. Энэ нь манай бүх загваруудын гар хатаагч мотор илүү удаан ажилладаг гэсэн үг юм.
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
