import React, { useState } from "react";
import { ItemCard3 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";
export default function Disinfection(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-contain bg-left bg-no-repeat p-6 overflow-y-auto flex justify-around items-center"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/user_upload/header-handdryer2.png")`,
        }}
      >
        <div className="w-2/6"></div>
        <div className="w-2/6 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">Disinfection</div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Dry your hands in 9-11 seconds. Quiet, fast and environmentally
            friendly.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col w-[60%] items-center top-12 gap-16 my-16">
        <div className="flex justify-center px-6 gap-6">
          <div className="flex flex-col text-txtTT font-light w-1/4 gap-3">
            <div className=" font-normal">New design</div>
            You will love our URIMAT hand dryers and their attractive design.
          </div>
          <div className="flex flex-col text-txtTT font-light w-1/4 gap-3">
            <div className=" font-normal">
              Low-cost and environmentally friendly
            </div>
            Our hand dryers not only protect the environment, but also help to
            save costs. No storage, no disposal of paper and fabric towels. The
            energy requirements of our hand dryer are significantly lower than
            those of paper and fabric towel dispensers.
          </div>
          <div className="flex flex-col text-txtTT font-light w-1/4 gap-3">
            <div className=" font-normal">Absolute hygiene </div>
            With their contactless, sensor-controlled operation, our hand dryers
            are based on a clean technology.
          </div>
          <div className="flex flex-col text-txtTT font-light w-1/4 gap-3">
            <div className=" font-normal">Engine</div>
            With the brushless electric motor, there are no brushes (sliding
            contacts), hence there is no mechanical wear. This type of motor
            lasts significantly longer. Step regulation and hot and cold air
            operation are possible.
          </div>
        </div>
        <div className="flex row-span-3 gap-8 w-full">
          <div
            className="bg-contain bg-no-repeat bg-center w-1/3 aspect-[1/1]"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/fileadmin/produkte/hygieneartikel/urimat-hygienespender-wandspender_01.png)",
            }}
          />
          <div
            className="bg-contain bg-no-repeat bg-center w-1/3 aspect-[2/3]"
            style={{
              backgroundImage:
                "url(https://www.urimat.com/fileadmin/_processed_/4/e/csm_urimat-hygienespender-standfuss_01_5eaf864c05.png)",
            }}
          />
          <div className="flex flex-col w-1/3 gap-4 shadow-card rounded-tl-3xl rounded-br-3xl bg-white p-6 h-72">
            <h2 className="text-primary font-normal font-sans text-2xl card-title">
              Documents
            </h2>
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom  aspect-[3/4] h-40 bg-red-200"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
