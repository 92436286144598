import React from "react";
import "./App.css";
import AppRoutes from "./routes";

function App() {
  return (
    // <div className="h-screen w-screen relative">
    <AppRoutes />
    // </div>
  );
}

export default App;
