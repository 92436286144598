import React, { useState } from "react";
import { ItemCard4 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";

export default function InWallMountingFrame(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div className="w-full min-h-[65%] bg-gray-200 gap-8 overflow-y-auto flex items-center">
        <div
          className="w-1/2 h-full bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("https://www.urimat.com/fileadmin/produkte/unterputzrahmen/urimat_unterputz-rahmen.png")`,
          }}
        />
        <div className="w-1/3 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            In-wall mounting frame
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Dry your hands in 9-11 seconds. Quiet, fast and environmentally
            friendly.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
        </div>
      </div>
      <div className="w-[60%] flex flex-col items-center justify-center top-12 gap-16 my-16">
        <div className="flex gap-8 w-full">
          <div className="flex flex-col gap-8 w-2/3">
            <div className="text-primary text-3xl font-light ">
              The technology of waterless urinals
            </div>
            <div className="text-base text-txtTT font-extralight flex flex-col gap-4">
              Draining urine is fed through the funnel into the odour trap of
              the waterless urinal and then runs through the vertical membrane
              into the sewer system.
            </div>
            <iframe
              width="730"
              height="411"
              src="https://www.youtube.com/embed/iHIkdVUL2Oc"
              title="URIMAT MB-ActiveTrap, product description"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          <div className="bg-white shadow-card relative w-1/3 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center pb-20">
            <div className="flex flex-col gap-4">
              <h2 className="text-primary font-normal font-sans text-2xl card-title">
                Documents
              </h2>
              <div className="flex  w-full gap-4 h-40">
                <div
                  className=" bg-no-repeat bg-contain bg-bottom h-full aspect-[12/16]"
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                  }}
                />
                <div className="font-light">
                  Product Brochure URIMAT hand dryer
                </div>
              </div>
              <div className="flex w-full gap-4 h-40">
                <div
                  className=" bg-no-repeat bg-contain bg-bottom h-full aspect-[12/16]"
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                  }}
                />
                <div className="font-light">
                  Product Brochure URIMAT hand dryer
                </div>
              </div>
              <div className="flex w-full gap-4 h-40">
                <div
                  className=" bg-no-repeat bg-contain bg-bottom h-full aspect-[12/16]"
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                  }}
                />
                <div className="font-light">
                  Product Brochure URIMAT hand dryer
                </div>
              </div>
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
        </div>
        <div className="flex row-span-2 gap-8 w-full">
          <ItemCard4
            imageAspect="16/9"
            key="URIMAT eco"
            header="URIMAT eco"
            description="Hightech-plastic waterless urinal"
            imageUrl="https://www.urimat.com/fileadmin/produkte/unterputzrahmen/58.520-a003-01_ga-kerafix_duofixfeet.53.png"
            url="/products/urinals"
            title="Further Information"
          />
          <ItemCard4
            imageAspect="16/9"
            key="URIMAT ecoplus"
            header="URIMAT ecoplus"
            description="Hightech-plastic waterless urinal with advertising display"
            imageUrl="https://www.urimat.com/fileadmin/produkte/unterputzrahmen/58.520-a003-01_ga-kerafix_duofixfeet.53.png"
            url="/products/urinals"
            title="Further Information"
          />
        </div>
        <div className="flex flex-col gap-8">
          <div className="text-primary text-3xl font-light ">
            The technology of waterless urinals
          </div>
          <div className="text-base text-txtTT flex flex-col gap-4">
            <div className="w-full list-decimal font-extralight">
              A hand dryer is an electrical device for drying hands in the
              sanitary room by means of a generated cold or warm air flow. The
              integrated motor draws in the ambient air via a blower and then
              blows the heated air back via a germ filter (HEPA filter) into the
              air duct for drying hands. The function is triggered by a sensor
              to which you hold your hands. The warm air that flows out of the
              hand dryer ensures dry hands within a few seconds by simply
              evaporating the moisture - without the use of paper or cloth
              towels.
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-4">
          <div className="text-primary text-3xl font-light ">
            What should I look for in a hand dryer?
          </div>
          <ul className="text-txtTT font-extralight flex flex-col gap-3 list-disc list-inside">
            <li>
              <span className=" font-light">Drying time:</span> A good hand
              dryer should work quickly and effectively and dry the hands within
              a very short time (9-11 s). A fast drying time reduces the waiting
              time for other toilet users.
            </li>
            <li>
              <span className=" font-light">
                Easy cleaning of the hand dryer:
              </span>
              In order to avoid bacteria and germs when drying hands with a hand
              dryer or with the hand dryer itself, the dryer should be easy to
              clean, i.e. the HEPA filter and the water collection tray are
              integrated in the device and easy to change or clean. Touchless
              devices with a sensor are more hygienic than devices where the
              drying process has to be started with a switch/button.
            </li>
            <li>
              <span className=" font-light">Loudness: </span>
              {`A hand dryer must not become a noise nuisance, as too loud devices with a lot of power are quickly perceived as very annoying. A noise level of <80 dB during use is to be aimed for. With some hand dryer models, the air speed and thus the noise level can be individually adjusted between two levels with a switch. `}
            </li>
          </ul>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
