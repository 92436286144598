import React from "react";
import { ItemCard2 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";

export default function Urinals(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-contain bg-left-top bg-no-repeat p-6 overflow-y-auto flex justify-around items-center"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/user_upload/header-urimat-uebersicht_2020.png")`,
        }}
      >
        <div className="w-2/6"></div>
        <div className="w-2/6 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            Усгүй шээлтүүр
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Сайжруулсан хуванцар болон дээд зэргийн чанартай шаазан эдлэлийн усгүй шээлтүүрийн шилдэг загвар
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Усны хэрэглээгүй</li>
            <li>Химийн бодисгүй </li>
            <li>Хүлэмжийн хийг бууруулах</li>
            <li>Гарны оролцоогүй ажиллагаа</li>
          </ul>
        </div>
      </div>
      <div className="mb-16 -mt-16 flex flex-col w-4/5 -top-32 gap-12 items-center justify-center">
        <div className="w-full flex flex-col items-center gap-12">
          <div className="flex justify-center px-6">
            {/* <div className="w-2/9 h-60 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center justify-center bg-primary ">
              <ul className="flex flex-col gap-3 text-white text-opacity-70 italic justify-center">
                <li>Усны хэрэглээгүй</li>
                <li>No chemicals </li>
                <li>Basin production is CO2-neutral </li>
                <li>Touchfree operation</li>
              </ul>
            </div> */}
            <div className="flex flex-wrap row-span-3 gap-8 w-7/9 pl-8">
              <ItemCard2
                key="URIMAT eco"
                header="УРИМАТ эко"
                description="Ус ашиглахгүй, химийн бодис агуулагдаагүй, үнэргүй. Патентлагдсан үнэр баригч болон микробиологийн цэвэрлэгээний бодисууд нь эвдрэлгүй, үнэргүй ажиллах баталгаа болдог. Усгүй шээлтүүрийг URIMAT-д зориулан тусгайлан бүтээсэн өндөр чанартай, удаан эдэлгээтэй."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ecoplus"
                header="УРИМАТ эко+"
                description="Зар сурталчилгаа цаасан хэлбэрээр байршуулах боломжтой. Ус ашиглахгүй, химийн бодис агуулагдаагүй, үнэргүй. Патентлагдсан үнэр баригч болон микробиологийн цэвэрлэгээний бодисууд нь эвдрэлгүй, үнэргүй ажиллах баталгаа болдог. Усгүй шээлтүүрийг URIMAT-д зориулан тусгайлан бүтээсэн өндөр чанартай, удаан эдэлгээтэй."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ecovideo"
                header="УРИМАТ эко видео"
                description="Зар сурталчилгааг бичлэг хэлбэрээр тоглуулах боломжтой. Ус ашиглахгүй, химийн бодис агуулагдаагүй, үнэргүй. Патентлагдсан үнэр баригч болон микробиологийн цэвэрлэгээний бодисууд нь эвдрэлгүй, үнэргүй ажиллах баталгаа болдог. Усгүй шээлтүүрийг URIMAT-д зориулан тусгайлан бүтээсэн өндөр чанартай, удаан эдэлгээтэй."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ceramicC2"
                header="УРИМАТ шаазан"
                description="Цэвэр дизайн. Жижиг хэмжээтэй, усгүй шээлтүүрийг жижиг зайд ашиглах боломжтой.Манай керамик усгүй загварууд нь сэтгэл татам, тод  Патентлагдсан үнэр баригч болон микробиологийн цэвэрлэгээний бодисууд нь эвдрэлгүй, үнэргүй ажиллах баталгаа болдог."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ceramicC2-CS"
                header="УРИМАТ шаазан C2-CS"
                description="Бүрэн автомат цэвэрлэгээний систем болох URIMAT-CS-ийн тусламжтайгаар цаг хугацаа, мөнгөө хэмнэж, байгаль орчныг хамгаалаарай.  Ариун цэврийн шаардлага хангаж, цэвэрлэх хүчин чармайлтыг мэдэгдэхүйц бууруулдаг."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ceramic20"
                header="УРИМАТ шаазан20"
                description="Зөвхөн сайн чанарын үйлдвэрлэлийн аргаар хийсэн URIMAT шаазан20 аяга нь таны дотоод засалд өнгө нэмнэ. УРИМАТ-ын савны хурц ирмэгүүд бүрэн байхгүй байгаа нь аливаа хуримтлал үүсэхээс идэвхтэй сэргийлдэг."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT inox"
                header="URIMAT Inox"
                description="Усгүй шээлтүүр нь бүхэлдээ V2A зэвэрдэггүй гангаар хийгдсэн. Мембран үнэр баригчтай батлагдсан технологийг S-Trap хэлбэрийн зэвэрдэггүй ган хоолойд ашигладаг."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              {/* <ItemCard2
                key="URIMAT colouredurinals"
                header="URIMAT colouredurinals"
                description="Various designs and colors available for ceramic urinals on request"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              /> */}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-primary text-3xl font-semibold ">
              Усгүй суваг хийх технологи
            </div>
            <div className="text-1xl text-txtTT flex flex-col gap-4">
              <div>Усгүй шээсний сувгийн хоёр технологи байдаг:</div>
              <ul className="w-full list-decimal font-semibold px-10">
                <li>
                  Тос эсвэл химийн хайрцагнууд - {" "}
                  <span className="font-light">
                    шээс нь тос эсвэл химийн бодисоор дамждаг бөгөөд энэ нь үнэрийг багасгадаг лац үүсгэдэг. Энэ системийг ашиглах нь үнэтэй хайрцагнуудыг хэдэн сар тутамд солих шаардлагатай байдаг ба засвар үйлчилгээний зардал өндөр байдаг, ялангуяа тосны лацыг санамсаргүйгээр эвдэж, цэвэрлэгч шээлтүүрийг хувин усаар угаана.
                  </span>
                </li>
                <li>
                  URIMAT-ийн патентлагдсан ActiveTrap буюу мембран хавх - {" "}
                  <span className="font-light">
                    Мембран хаах технологи / мембран хавхлага нь 20 гаруй жилийн турш гүйцэтгэлээ баталсан бөгөөд усгүй шээсний хамгийн сүүлийн үеийн технологи юм. Энэ нь үнэр, эрүүл ахуйн асуудлаас урьдчилан сэргийлэхэд илүү үр дүнтэй бөгөөд хямд бөгөөд засвар үйлчилгээ хийхэд хялбар байдаг.
                  </span>
                </li>
              </ul>
              <div>
                URIMAT Urinals-д ашигладаг патентлагдсан, сольж болох ActiveTrap нь шээсийг цуглуулж (ус ашиглахгүйгээр) ус зайлуулах систем рүү гаргадаг. Бохирдуулагч тос, химийн бодис хэрэглэдэггүй бөгөөд үнэр гарахгүй.
              </div>
            </div>
          </div>
          <div className="text-primary text-3xl font-semibold ">
            Усгүй шээлтүүр хэрхэн ажилладаг вэ?
          </div>
          <div className="flex w-full gap-8">
            <div className="flex flex-col w-1/2">
              <ul className="text-txtTT flex flex-col gap-3 list-disc">
                <li>
                  Цуглуулсан шээсийг юүлүүрээр дамжуулан үнэр баригч руу урсгаж, босоо байрлалтай мембранаар дамжин бохирын систем рүү урсдаг.
                </li>
                <li>
                  Шээс нь урсахаа болих үед мембран хаагдаж, бохир усны системээс хий гарахаас сэргийлдэг.
                </li>
                <li>
                  Нэгдсэн MB-ActiveCube нь эрүүл ахуйг сайжруулж, хуримтлалыг багасгаж, үнэр баригч дотор болон хаягдал хоолой нь органик бодис, шээсний хуримтлал, өөх тосоор бүрхэгдэхээс сэргийлдэг.
                </li>
              </ul>
            </div>

            <div className="bg-white shadow-card w-1/2 aspect-3/1 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center">
              <ul className="flex flex-col gap-6 list-inside list-disc">
                <li>
                  MB-ActiveCube ууссаны дараа тунгалаг бүрхүүлийн доорх улаан гадаргуу нь үнэр баригчийг солих шаардлагатайг илтгэнэ.
                </li>
                <li>
                  Босоо мембраны технологи нь үнэрийг бүрэн битүүмжилж, сөрөг болон эерэг даралтыг тэсвэрлэх чадвартай тул бохирын системээс хий гарах боломжгүй юм.
                </li>
              </ul>
              <div
                className=" bg-no-repeat bg-contain bg-center aspect-[1/1] w-[100%]"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/produkte/activetrap/trap_ansicht_funktion-transparent.png)`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
