import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function AboutUsOverview(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-cover bg-center bg-no-repeat overflow-y-auto flex flex-col justify-end items-start p-16 px-[20%] text-white gap-8"
        style={{
          backgroundImage: `linear-gradient( hsla(202, 100%, 30%, 0.8), hsla(202, 100%, 35%, 0.8) ),
          url("https://www.urimat.com/fileadmin/_processed_/d/f/csm_gletscher-header-1920x1080_a0fa328b2f.jpg")`,
        }}
      >
        <div className="w-1/3 text-3xl">
          Байгаль орчинд ээлтэй, эдийн засгийн хэмнэлттэй шийдэл.
        </div>
        <div className="w-full flex">
          <div className="w-1/3 h-2/3 font-medium text-3xl text-center">
            100,000 л<div className="font-normal"></div>
            <div className="font-light">
              Шээлтүүр бүр жилд дунджаар 100,000 литр ундны ус хэмнэдэг.
            </div>
          </div>
          <div className="w-1/3 h-2/3 font-medium text-3xl text-center">
            10'000'000 MNT<div className="font-normal">Зардлын үр ашиг</div>
            <div className="font-light">
              Хэрэв та цаасны оронд URIMAT гар хатаагч хэрэглэвэл жилийн зардлыг хэмнэх боломжтой
            </div>
          </div>
          <div className="w-1/3 h-2/3 font-medium text-3xl text-center">
            350 гр
            <div className="font-normal">
              CO<sub>2</sub> бууруулах
            </div>
            <div className="font-light">
              Нэг шоо метр усанд ойролцоогоор 350г CO2 хэмнэгддэг.
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center my-16">
        <div className="flex flex-col w-[60%] items-center  top-12 gap-16">
          <div className="flex justify-center px-6 gap-6 text-secondary text-3xl">
            <div className="font-light flex flex-col gap-4 w-1/2">
              <div className="text-primary text-5xl">URIMAT-д тавтай морил</div>
              <div className="text-txtTT">
                УРИМАТ компани нь байгальд ээлтэй усгүй шээлтүүр үйлдвэрлэдэг тэргүүлэгчдийн нэг юм.
              </div>
            </div>
            <div className=" w-1/2 text-xl font-light text-txtTT">
              Urimat Schweiz AG компани нь 1998 оноос хойш тасралтгүй байгальд ээлтэй бүтээгдэхүүн, усгүй шээлтүүр үйлдвэрлэдэг тэргүүлэгчдийн нэг юм. 
              Швейцарын Уримат үйлдвэрлэгчийн усгүй шээлтүүр нь дэлхий даяар олон мянган эрэгтэй хүний бие засах газруудад эрүүл ахуй, хүрээлэн буй орчныг хамгаалж, өргөнөөр ашиглаж хүлээн зөвшөөрөгдсөн. Өндөр технологийн хуванцар материал, дэвшилтэт мембран технологи дээр суурилж микробиологийн цэвэрлэх системтэй СО2-ийг саармагжуулах, усаар урсгах шаардлагагүй, 100% дахин боловсруулах боломжтой.
            </div>
          </div>
          <div className="w-full flex justify-center px-6 gap-6 text-secondary text-3xl">
            <div
              className="w-1/2 bg-contain bg-no-repeat bg-center aspect-[1/1]"
              style={{
                backgroundImage:
                  "url(https://www.urimat.com/fileadmin/_processed_/f/b/csm_urimat-oekologie-umweltkreislauf_963ae67b3b.png)",
              }}
            />
            <div className="font-light flex flex-col gap-4 w-1/2">
              <div className="text-primary text-4xl">URIMAT-ын усгүй шээлтүүр нь байгаль орчинд ээлтэй систем юм</div>
              <ul className="text-txtTT text-xl font-light gap-3 list-decimal list-inside">
                <li>Өндөр технологийн хуванцар, СО2-ийг саармагжуулах, 100% дахин боловсруулах боломжтой</li>
                <li>Нэгдсэн цэвэрлэх систем бүхий үнэр баригч өвөрмөц технологи</li>
                <li>Микробиологийн цэвэрлэх систем</li>
                <li>Шээлтүүр дээр зар сурталчилгаагаа байршуулж нэмэлт санхүүжилттэй болж болно</li>
              </ul>
            </div>
          </div>
          <div className="w-full flex gap-6 row-3">
            <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
              <div className="flex flex-col text-txtTT gap-4 justify-between ">
                <h2 className="font-normal font-sans text-2xl card-title">
                  Үр дүнтэй ногоон шийдэл
                </h2>
                <div className="font-light h-2/3">
                URIMAT нь цэвэр, ашигтай, байгаль орчныг хамгаалах Solar Impulse Foundation 1000 шийдлийн нэг хэсэг юм.
                </div>
              </div>
              <div
                className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/e/a/csm_sif_label_logo_institutional_2020_rvb_a6a7b5af58.png)`,
                }}
              />
            </div>
            <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
              <div className="flex flex-col text-txtTT gap-4 justify-between ">
                <h2 className="font-normal font-sans text-2xl card-title">
                  НҮБ-ын SDG
                </h2>
                <div className="font-light h-2/3">
                  Бид НҮБ-ын дараах Тогтвортой хөгжлийн зорилтуудыг (ТХЗ) дэмжиж байна.
                </div>
              </div>
              <div
                className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/5/c/csm_urimat_un_sdg_goals_a1e7a04848.png)`,
                }}
              />
            </div>
            <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
              <div className="flex flex-col text-txtTT gap-4 justify-between ">
                <h2 className="font-normal font-sans text-2xl card-title">
                  Байгаль орчин, эдийн засаг хоёр хамт оршдог
                </h2>
                <div className="font-light h-2/3">
                  Цэвэр усны нөөцөө хадгалж, мөнгөө хэмнээрэй.
                </div>
              </div>
              <div
                className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/9/0/csm_u.s._green_building_council_logo.svg_0b9e58e153.png)`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
